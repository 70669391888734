import axios from 'axios';

export const BASEURL = `${window.location.protocol}//${window.location.host}`;
export const PROXY_URL =
    import.meta.env.VITE_PROXY_URL === undefined ? 'http://localhost:3001' : import.meta.env.VITE_PROXY_URL;

const config = {
    BASEURL,
    PROXY_URL,

    // overridden by config.load() --->
    MAPUPDATE_API_URL: `${PROXY_URL}/api/mapupdate`,
    MAPUPDATEPROXY_API_URL: `${PROXY_URL}/api/mapupdate-proxy`,
    ONLINETRAFFIC_API_URL: `${PROXY_URL}/api/onlinetraffic`,
    ONLINETRAFFICPROXY_API_URL: `${PROXY_URL}/api/onlinetraffic-proxy`,
    FEATUREDASHBOARD_API_URL: `${PROXY_URL}/api/featuredashboard`,
    FEATUREDASHBOARDPROXY_API_URL: `${PROXY_URL}/api/featuredashboard-proxy`,

    AUTH_ISSUER: 'https://cognito-idp.eu-west-1.amazonaws.com/eu-west-1_dNXyjKCJV',
    AUTH_CLIENT_ID: '49qgq8i19se3oh55vflvn24963',
};

// load dynamic configuration (envs) from proxy
config.load = () =>
    axios
        .get(`${PROXY_URL}/config`)
        .then((res) => Object.assign(config, res.data))
        .catch((err) => console.error('Unable to load configuration', err));

export default config;
